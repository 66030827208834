import { ADD_MENU_ITEM, DELETE_MENU_ITEM, GET_MENU_ITEMS, UPDATE_MENU_ITEM } from "../actions/menuItems";

const menuItemsReducer = (state = [], action) => {
    switch (action.type) {
        case GET_MENU_ITEMS:
            return action.payload;
        case ADD_MENU_ITEM:
            return [...state, action.payload];
        case UPDATE_MENU_ITEM:
            const updatedItems = state.map(item => (item._id === action.payload._id) ? action.payload : item);
            return updatedItems;
        case DELETE_MENU_ITEM:
            return state.filter(item => item._id !== action.payload);
        default:
            return state;
    }
}

export default menuItemsReducer;