import axios from "axios";
import { resHeader, serverUrl, tele } from "../lib";

export const GET_MENU_CATEGORIES = 'GET_MENU_CATEGORIES';
export const ADD_MENU_CATEGORY = 'ADD_MENU_CATEGORY';
export const UPDATE_MENU_CATEGORY = 'UPDATE_MENU_CATEGORY';
export const DELETE_MENU_CATEGORY = 'DELETE_MENU_CATEGORY';

//Action creators
export const getMenuCategories = (categories) => {
    return {
        type: GET_MENU_CATEGORIES,
        payload: categories
    }
}

export const addMenuCategory = (category) => {
    return {
        type: ADD_MENU_CATEGORY,
        payload: category
    }
}

export const updateMenuCategory = (category) => {
    return {
        type: UPDATE_MENU_CATEGORY,
        payload: category
    }
}

export const deleteMenuCategory = (id) => {
    return {
        type: DELETE_MENU_CATEGORY,
        payload: id
    }
}

//Thunk creators

export const fetchMenuCategoriesThunk = () => {
    return async (dispatch) => {
        try {
            const { data } = await axios.get(`${serverUrl}/api/menu/categories/?${tele.initData}`, resHeader);
            // console.log('THUNK', data)
            dispatch(getMenuCategories(data))
        } catch (err) {
            console.log(err)
        }
    }
}

export const addMenuCategoryThunk = (data) => {
    return async (dispatch) => {
        try {
            const res = await axios.post(`${serverUrl}/api/menu/categories`, data, resHeader);
            if (!res.data.success) throw 'Error'

            dispatch(addMenuCategory(res.data.newCategory))

            // console.log('addMenuCategoryThunk', res.data);
        } catch (err) {
            console.log(err);
        }
    }
}

export const updateMenuCategoryThunk = (data) => {
    return async (dispatch) => {
        try {
            const res = await axios.put(`${serverUrl}/api/menu/categories/${data.category._id}`, data, resHeader)
            dispatch(updateMenuCategory(data.category));
            // console.log(res.data)
        } catch (err) {
            console.log(err);
        }
    }
}

export const deleteMenuCategoryThunk = (id) => {
    return async (dispatch) => {
        try {
            const res = await axios.delete(`${serverUrl}/api/menu/categories/${id}/?${tele.initData}`, resHeader);
            dispatch(deleteMenuCategory(id))
            // console.log('deleteMenuCategoryThunk', res.data)
        } catch (err) {
            console.log(err);
        }
    }
}



