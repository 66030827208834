import React, { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux';
import { fetchItemsThunk } from '../../actions/items';
import AddBuilderItem from '../AddBuilderItem/AddBuilderItem';
import EditBuilderItem from '../EditBuilderItem/EditBuilderItem';
import { Button, IconButton } from '@mui/material';
import { ArrowBack } from '@mui/icons-material';

import './BuilderItemsPortal.css'
import { useNavigate } from 'react-router-dom';

function BuilderItemsPortal() {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [portal, setPortal] = useState('edit');


    useEffect(() => {
        dispatch(fetchItemsThunk()); //Get items from inventory
    }, [])


    const togglePortal = (portal) => {
        switch (portal) {
            case 'add':
                return (<AddBuilderItem portal={portal} setPortal={setPortal} />)
            case 'edit':
                return (<EditBuilderItem portal={portal} />)
            default:
                return ''
        }
    }

    return (
        <div className='builder_items_portal_container'>
            <div style={{ textAlign: 'left', width: '100%' }}>
                <IconButton onClick={() => navigate('/admin')}>
                    <ArrowBack /><span>Back</span>
                </IconButton>
            </div>
            <div className='builder_items_btns'>
                <Button disabled={portal === 'add'} variant='contained' onClick={() => setPortal('add')}>
                    Add Builder Item
                </Button>
                <Button disabled={portal === 'edit'} variant='contained' onClick={() => setPortal('edit')}>
                    Edit Builder Items
                </Button>
            </div>
            <div className='builder_items_container'>
                {togglePortal(portal)}
            </div>
        </div>
    )
}

export default BuilderItemsPortal