import React, { useState, useEffect } from 'react'
import { MenuItem as Option, Select, InputLabel, OutlinedInput } from '@mui/material';
import './BuilderItemForm.css'
import { themeParams } from '../../lib';
import { useDispatch, useSelector } from 'react-redux';
import { fetchBuilderCategoriesThunk } from '../../actions/builderCategories';

function BuilderItemForm({ handleForm, setFormData, formData, item }) {

    const [editItem, setEditItem] = useState({});

    const dispatch = useDispatch();
    const { builderCategories } = useSelector(state => state);

    useEffect(() => {
        dispatch(fetchBuilderCategoriesThunk());
    }, [])

    useEffect(() => {
        if (item !== undefined) {
            setEditItem(item);
            setFormData({ ...item, imageUrl: item.imageUrl });
        }
    }, [item]);

    useEffect(() => {
        addItemToForm();
    }, [editItem]);

    const addItemToForm = () => {
        if (editItem._id !== undefined) {
            // const itemKeys = Object.keys(item);
            const itemKeys = ['name', 'type', 'imageUrl', 'price'];
            itemKeys.forEach(key => {

                const element = (key !== 'description') ? document.getElementsByName(key)[0] : document.getElementsByName(key)[1];

                if (element !== null) {
                    if (key === 'imageUrl') {
                        console.log('KEY', key, item.imageUrl);
                        element.value = item.imageUrl ? item.imageUrl : 'https://via.placeholder.com/100.png';
                    } else {
                        console.log('KEY', key);
                        element.value = item[key];
                    }
                }

            });
        }
    }

    const handleChange = (e) => {
        const { name, value } = e.target;

        setFormData({ ...formData, [name]: value });
        console.log(formData);

    }


    return (
        <div className='item_form_container'>
            <form id="item_form" action="" onSubmit={(e) => handleForm(e, formData)}>
                <img src={formData.imageUrl ? formData.imageUrl : 'https://via.placeholder.com/100.png'} alt={formData.name} width="200px" />

                <label htmlFor="name">Name</label>
                <OutlinedInput
                    size='small'
                    name={'name'}
                    color='primary'
                    onChange={handleChange}
                    sx={{ paddingLeft: '5px', backgroundColor: 'white' }}
                />

                <InputLabel id='item_type_label' sx={{ color: themeParams.text_color }}>Type</InputLabel>
                <Select
                    labelId='Type'
                    name={'type'}
                    value={formData.type}
                    variant='outlined'
                    sx={{ paddingLeft: '20px', backgroundColor: 'white' }}
                    onChange={handleChange}
                    displayEmpty
                >
                    <Option value="">Choose Type</Option>
                    {
                        builderCategories.map(({ name }, idx) => {
                            return <Option key={idx} value={name}>{name[0].toUpperCase() + name.slice(1, name.length)}</Option>
                        })
                    }
                    {/* <Option value="cheese">Cheese</Option>
                    <Option value="meat">Meat</Option>
                    <Option value="produce">Produce</Option>
                    <Option value="condiment">Condiment</Option> */}
                </Select>

                <label htmlFor="imageUrl">Image</label>
                <OutlinedInput
                    size='small'
                    name={'imageUrl'}
                    placeholder={'Link to Image'}
                    color='primary'
                    onChange={handleChange}
                    sx={{ paddingLeft: '5px', backgroundColor: 'white' }}
                    focused
                />

                <label htmlFor="price">Price (optional)</label>
                <OutlinedInput
                    size='small'
                    name={'price'}
                    placeholder={'5, 9.99, etc.'}
                    color='primary'
                    onChange={handleChange}
                    sx={{ paddingLeft: '5px', backgroundColor: 'white' }}
                />
                <br />
                <button className='btn' type='submit'>
                    Submit Item
                </button>

            </form>
        </div>
    )
}

export default BuilderItemForm