import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { deleteItemThunk } from '../../actions/items';

function BuilderItemCard({itemType, editItem}) {
    const dispatch = useDispatch();
    const {items} = useSelector(state => state);

    return (
        <div className='item-card-container'>
            <h4>{itemType[0].toUpperCase() + itemType.slice(1, itemType.length)}</h4>
            {
                items.map((item, idx) => {
                    if (item.type === itemType) {
                        return (
                            <div key={idx} className="item-card">
                                <img src={item.imgUrl} width="30px" />
                                <span>{item.name}</span>
                                <div className='item_card_buttons'>
                                    <button onClick={() => editItem(item)}>Edit</button>
                                    <button onClick={() => dispatch(deleteItemThunk(item._id))}>Delete</button>
                                </div>
                            </div>
                        )
                    }
                })
            }
        </div>
    )
}

export default BuilderItemCard