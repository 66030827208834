import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import axios from 'axios';
import './Cart.css'
import Button from '../Button/Button';
import { setCartAction, clearCartAction, updateCartItemAction } from '../../actions/cart';
import { clearSandwichAction } from '../../actions/sandwich';
import ViewCart from '../ViewCart/ViewCart';
import { resHeader, serverUrl, tele } from '../../lib';
import { Box, IconButton, Modal } from '@mui/material';
import { Close } from '@mui/icons-material';

function Cart({ onCheckout, open, closeCart }) {

	const { cart } = useSelector(state => state);
	const dispatch = useDispatch();

	const totalPrice = (cart && cart.length !== 0) ? cart.reduce((acc, cartItem) => acc + cartItem.price * cartItem.quantity, 0) : 0;

	const [showCart, setShowCart] = useState(false)

	useEffect(() => {
		const localCart = window.localStorage.getItem('cart');
		if (localCart !== null) dispatch(setCartAction(JSON.parse(localCart)));
	}, []);

	// useEffect(() => {

	// 	// if (cart) {
	// 	// 	if (cart.length !== 0) {
	// 	// 		window.localStorage.setItem('cart', JSON.stringify(cart));
	// 	// 	}

	// 	// }
	// 	// console.log('FULL CART', cart)
	// }, [cart]);

	const clearCart = () => {
		window.localStorage.removeItem('cart');
		dispatch(clearCartAction());
	}

	const toggleCart = () => {
		setShowCart(!showCart)
	}

	const modalStyle = {
		position: 'absolute',
		color: 'black',
		top: '50%',
		left: '50%',
		transform: 'translate(-50%, -50%)',
		width: 400,
		bgcolor: 'background.paper',
		// border: '2px solid #000',
		boxShadow: 24,
		p: 4,
	};

	return (
		<Modal
			open={open}
			onClose={closeCart}
		>
			<Box sx={modalStyle}>
				<IconButton style={{ position: 'relative', top: '-20px', left: '10px' }} onClick={closeCart}>
					<Close />
				</IconButton>
				<div className='cart_container'>


					{/* <span>Subtotal: ${totalPrice.toFixed(2)}</span>
					<span>Tax: ${(totalPrice * 0.08875).toFixed(2)}</span>
					<span>Total: ${(totalPrice + (totalPrice * 0.08875)).toFixed(2)}</span> */}
					<br />
					{(cart) ? (cart.length === 0) ? <span style={{ fontWeight: 'bold' }}>No items in cart</span> : '' : <span style={{ fontWeight: 'bold' }}>No items in cart</span>}
					<br />
					{(cart) ? (cart.length === 0) ? '' : (
						<div className='cart_buttons'>
							<button onClick={toggleCart}>{!showCart ? 'View Items' : 'Hide Items'} ({cart.reduce((acc, item) => acc + item.quantity, 0)})</button>
							<button onClick={clearCart}>Clear Cart</button>
						</div>

					) : ''}

					{
						showCart === true ? <ViewCart /> : ''
					}

					<table>
						<tr>
							<td>Subtotal: </td>
							<td>${totalPrice.toFixed(2)}</td>
						</tr>
						<tr>
							<td>Tax (8.875%): </td>
							<td>${(totalPrice * 0.08875).toFixed(2)}</td>
						</tr>
						<tr>
							<td>Total: </td>
							<td>${(totalPrice + (totalPrice * 0.08875)).toFixed(2)}</td>
						</tr>
					</table>
					<br />
					{
						(cart.length !== 0) ? (<Button
							id={'checkout_btn'}
							title={'Checkout'}
							disable={cart.length === 0 ? true : false}
							onClick={onCheckout}
						/>) : ''
					}

					<br />
				</div>
			</Box>
		</Modal>
	)
}

export default Cart