
//Items reducer
export const itemsReducer = (state = [], action) => {
    switch (action.type) {
        case 'GET_ITEMS':
            return action.payload;
        case 'ADD_ITEM':
            return [...state, action.payload];
        case 'UPDATE_ITEM':
            const updatedItemState = state.map(item => (item._id === action.payload._id) ? action.payload : item);
            return updatedItemState;
        case 'DELETE_ITEM':
            const deletedItemState = state.filter(item => {
                if (item._id !== action.payload) return item
            });
            return deletedItemState
        default:
            return state
    }
}

export default itemsReducer