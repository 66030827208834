import React, { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import BuilderItemsList from '../BuilderItemsList/BuilderItemsList';
import BuilderItemForm from '../BuilderItemForm/BuilderItemForm';
import { updateItemThunk } from '../../actions/items';
import { tele } from '../../lib';

function EditBuilderItem() {

	const { items } = useSelector(state => state);

	const [currentItem, setCurrentItem] = useState({});
	const [formData, setFormData] = useState({
		name: '',
		type: '',
		imageUrl: '',
		price: 0
	})

	const dispatch = useDispatch();


	//Handles form data and submits it to the appropriate channels
	const handleForm = (e, formData) => {
		e.preventDefault();

		try {
			dispatch(updateItemThunk({
				userData: tele.initData,
				item: formData
			}));

			console.log('updateItemThunk', currentItem)

			tele.showPopup({ title: '', message: 'Item successfully updated!', buttons: [{ type: 'ok' }] });
			const formFields = Object.keys(formData);
			formFields.forEach(field => {
				setFormData({ ...formData, [field]: '' });
				// document.getElementsByName(field)[0].value = '';
			});

			setCurrentItem({});
		} catch (err) {
			tele.showAlert('Oops, there was a problem. Try again.');
			console.log(err)
		}
		console.log('Submitted')
	}

	return (
		<div className='edit_item_portal'>
			{
				(items.length === 0) ? <h2>No items to edit</h2> : <h2>Edit items</h2>
			}

			{
				(currentItem._id === undefined) ? <BuilderItemsList setCurrentItem={setCurrentItem} /> : <BuilderItemForm item={currentItem} formData={formData} setFormData={setFormData} handleForm={handleForm} />
			}

		</div>
	)
}

export default EditBuilderItem