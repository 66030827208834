import React from 'react'
import './Tos.css'

function Tos() {
	return (
		<div className='tos_container'>
			<h2>Terms of Service</h2>

			<span>This is a sample ToS to be shown as an example for this sample shop.</span>
			<br />
			<span>You agree to the following by using this service:</span>
			<ul>
				<li>Any data you input into this service is fictitious</li>
				<li>We are not liable for any information shared with this service</li>
				<li>You will not attempt to/actively tamper with our services</li>
			</ul>

		</div>
	)
}

export default Tos