
const SET_CART = 'SET_CART';
const ADD_TO_CART = 'ADD_TO_CART';
const REMOVE_FROM_CART = 'REMOVE_FROM_CART'
const UPDATE_CART_ITEM = 'UPDATE_CART_ITEM'
const CLEAR_CART = 'CLEAR_CART'

export const setCartAction = (cart) => {
    return {
        type: SET_CART,
        payload: cart
    }
}

export const addToCartAction = (item) => {
    return {
        type: ADD_TO_CART,
        payload: item
    }
}

export const removeFromCartAction = (idx) => {
    return {
        type: REMOVE_FROM_CART,
        payload: idx
    }
}

export const updateCartItemAction = (item, price) => {
    return {
        type: UPDATE_CART_ITEM,
        payload: {item, price}
    }
}

export const clearCartAction = () => {
    return {
        type: CLEAR_CART
    }
}