import React, { useEffect, useState } from 'react'
import './UserOrderItem.css'

function UserOrderItem({ order }) {

	const [cart, setCart] = useState([])

	useEffect(() => {
		setCart(order.cart.filter(item => item.type !== 'tax'))
		console.log(cart)
	}, []);

	return (
		<div className='user_order_item_container'>
			<span>{order.orderNumer}</span>
			<div>
				{
					cart.map((item, idx) => {
						return (
							<div className='user_order_item' key={idx}>
								<span>
									{`${item.quantity} ${item.breadType || item.name} (${item.sandwichTemp || item.type}) ${item.sandwichItems ? item.sandwichItems.map(sandwichItem => sandwichItem.name).join(', ') : item.description}`}
								</span>
							</div>
						)
					})
				}
			</div>
		</div>
	)
}

export default UserOrderItem