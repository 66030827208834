import React from 'react'
import { useDispatch } from 'react-redux'
import { deleteMenuCategoryThunk } from '../../actions/menuCategories';
import MenuCategoryForm from '../MenuCategoryForm/MenuCategoryForm';
import './EditCategoryCard.css'
import { deleteBuilderCategoryThunk } from '../../actions/builderCategories';


function EditCategoryCard({ categoryType, category, edit, setEdit }) {

    const dispatch = useDispatch();

    const deleteCategory = (categoryType, categoryId) => {
        if(categoryType === 'menu'){
            dispatch(deleteMenuCategoryThunk(categoryId));
        }else if(categoryType === 'builder'){
            dispatch(deleteBuilderCategoryThunk(categoryId));
        }
    }

    return (
        <div className='category_card item-card'>
            {
                (edit.id !== category._id) ? (
                    <>
                        <span>{category.name}</span>
                        <div className='category_card_buttons'>
                            <button onClick={() => setEdit({status: true, id: category._id})}>Edit</button>
                            <button onClick={() => deleteCategory( categoryType, category._id)}>Delete</button>
                        </div>
                    </>
                ) : <MenuCategoryForm categoryType={categoryType} category={category} setEdit={setEdit} />

            }
        </div>
    )

}

export default EditCategoryCard