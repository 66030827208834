import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { setSandwichTempAction, setBreadTypeAction, clearSandwichAction } from '../../actions/sandwich';
import ItemsRow from '../ItemsRow/ItemsRow';
import { addToCartAction } from '../../actions/cart';
import Button from '../Button/Button';
import { tele } from '../../lib';

function SandwichBuilder(props) {
    const {
        primaryItems,
        onAdd,
        onRemove,
        sandwichPrices
    } = props;

    const { items, sandwich, builderCategories } = useSelector(state => state);
    const { breadType, sandwichTemp } = sandwich;

    const [added, setAdded] = useState(false);
    const [price, setPrice] = useState(0);

    const dispatch = useDispatch();

    useEffect(() => {
        // if(builder){
        const coldTempButton = document.querySelector('#cold_temp');
        const hotTempButton = document.querySelector('#hot_temp')

        if (sandwich.sandwichTemp === 'cold') {
            coldTempButton.style.backgroundColor = '#76bdd5';
            hotTempButton.style.backgroundColor = 'gray';
        } else if (sandwich.sandwichTemp === 'hot') {
            coldTempButton.style.backgroundColor = 'gray';
            hotTempButton.style.backgroundColor = 'coral';
        } else {
            coldTempButton.style.backgroundColor = 'gray';
            hotTempButton.style.backgroundColor = 'gray';
        }
        // }
        // console.log(sandwichTemp)
    }, [sandwichTemp]);

    useEffect(() => {
        changePrice()
    }, [breadType]);

    useEffect(() => {
        changePrice()
    }, [sandwich.sandwichItems]);

    const changePrice = async () => {
        try {
            const itemsPrice = sandwich.sandwichItems.reduce((accum, item) => (item.price !== 0) ? accum + item.price : accum + 0, 0)

            if (breadType !== '') {
                setPrice(sandwichPrices.find(price => price.name === breadType).price + itemsPrice)
            } else {
                setPrice(0 + itemsPrice);
            }
        } catch (err) {
            console.log(err);
        }
        // console.log('PRICE', price)
    }


    const addToCart = () => {
        if (sandwich.breadType !== '' && sandwich.sandwichTemp !== '') {
            const cartItem = { ...sandwich, price, quantity: 1 }
            const localCart = JSON.parse(window.localStorage.getItem('cart') || '[]');
            localCart.push(cartItem)
            window.localStorage.setItem('cart', JSON.stringify(localCart));
            // dispatch(updateCartItemAction({...sandwich, quantity: 1}, price));
            dispatch(addToCartAction(cartItem));
            dispatch(clearSandwichAction());

            console.log('SANDWISH', sandwich);

            document.querySelector('#sandwich_type').value = '';
            setAdded(true);
        } else {
            tele.showAlert('Must selected a type and set a temperature')
        }
    }

    const changeBread = (e) => {
        dispatch(setBreadTypeAction(e.target.value));
    }

    const changeTemp = (temp) => {
        dispatch(setSandwichTempAction(temp));
    }

    const filterItemsByType = (type, items) => {
        return items.filter(item => {
            if (item.type === type) return item
        })
    }

    const displayBuilderItemsByCategory = () => {
        const categoryNames = [];

        items.forEach(item => {
            if (!categoryNames.includes(item.type)) {
                categoryNames.push(item.type)
            }
        })
        // console.log('category name', categoryNames)

        return categoryNames.map((category, idx) => {
            return (
                <>
                    <h4 style={{ fontWeight: 'bold', fontSize: '1.2em' }}>{category}</h4>
                    {
                        <ItemsRow
                            items={filterItemsByType(category, items)}
                            itemType={category}
                            onAdd={onAdd}
                            onRemove={onRemove}
                            primaryItems={primaryItems}
                            added={added}
                            setAdded={setAdded}
                        />

                    }
                </>
            )
        })
    }

    return (
        <div>
            <div className='bread_type'>
                <span style={{ fontWeight: 'bold', fontSize: '1.2em', marginBottom: '5px' }}>Choose Type <span style={{ color: 'red' }}>*</span></span>
                <select style={{ width: '80%' }} name="sandwich_type" id="sandwich_type" onChange={changeBread}>
                    <option value="">Select a type</option>
                    {
                        sandwichPrices.map((type, idx) => {
                            return <option value={type.name} key={idx}>{`${type.name[0].toUpperCase() + type.name.slice(1, type.name.length)} $${type.price.toFixed(2)}`}</option>
                        })
                    }
                </select>
            </div>
            <br />
            <div className='sandwich_temp_container'>
                <div id='cold_temp' onClick={() => changeTemp('cold')}>
                    <span>Cold</span>
                </div>

                <div id='hot_temp' onClick={() => changeTemp('hot')}>
                    <span>Hot</span>
                </div>
            </div>

            <div className='cards_container'>

                {/* {
                    builderCategories.filter(category => {
                        return (
                            <>
                                <h4>{category.name}</h4>
                                <ItemsRow
                                    items={filterItemsByType(category.name, items)}
                                    itemType={category.name}
                                    onAdd={onAdd}
                                    onRemove={onRemove}
                                    primaryItems={primaryItems}
                                    added={added}
                                    setAdded={setAdded}
                                />
                            </>
                        )
                    })
                } */}

                {
                    displayBuilderItemsByCategory()
                }
                {/* <h4>Meats</h4>
                <ItemsRow
                    items={filterItemsByType('meat', items)}
                    itemType={'meat'}
                    onAdd={onAdd}
                    onRemove={onRemove} primaryItems={primaryItems}
                    added={added}
                    setAdded={setAdded}
                />

                <h4>Cheese</h4>
                <ItemsRow
                    items={filterItemsByType('cheese', items)}
                    itemType={'cheese'}
                    onAdd={onAdd}
                    onRemove={onRemove}
                    primaryItems={primaryItems}
                    added={added}
                    setAdded={setAdded}
                />

                <h4>Produce</h4>
                <ItemsRow
                    items={filterItemsByType('produce', items)}
                    itemType={'produce'}
                    onAdd={onAdd}
                    onRemove={onRemove}
                    primaryItems={primaryItems}
                    added={added}
                    setAdded={setAdded}
                />

                <h4>Condiments</h4>
                <ItemsRow
                    items={filterItemsByType('condiment', items)}
                    itemType={'condiments'}
                    onAdd={onAdd}
                    onRemove={onRemove}
                    primaryItems={primaryItems}
                    added={added}
                    setAdded={setAdded}
                /> */}
            </div>
            {
                (sandwich.sandwichItems.length !== 0) ? (<Button
                    id={'add_to_cart'}
                    title={'Add To Cart'}
                    disable={sandwich.sandwichItems.length === 0 ? true : false}
                    onClick={addToCart}
                    hide={sandwich.sandwichItems.length === 0 ? true : false}
                />) : ''
            }
        </div>
    )
}

export default SandwichBuilder