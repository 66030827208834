import axios from "axios";
import { resHeader, serverUrl, tele } from "../lib";

export const GET_BUILDER_CATEGORIES = 'GET_BUILDER_CATEGORIES';
export const ADD_BUILDER_CATEGORY = 'ADD_BUILDER_CATEGORY';
export const UPDATE_BUILDER_CATEGORY = 'UPDATE_BUILDER_CATEGORY';
export const DELETE_BUILDER_CATEGORY = 'DELETE_BUILDER_CATEGORY';

//Action creators
export const getBuilderCategories = (categories) => {
    return {
        type: GET_BUILDER_CATEGORIES,
        payload: categories
    }
}

export const addBuilderCategory = (category) => {
    return {
        type: ADD_BUILDER_CATEGORY,
        payload: category
    }
}

export const updateBuilderCategory = (category) => {
    return {
        type: UPDATE_BUILDER_CATEGORY,
        payload: category
    }
}

export const deleteBuilderCategory = (id) => {
    return {
        type: DELETE_BUILDER_CATEGORY,
        payload: id
    }
}

//Thunk creators

export const fetchBuilderCategoriesThunk = () => {
    return async (dispatch) => {
        try {
            const { data } = await axios.get(`${serverUrl}/api/builder/categories/?${tele.initData}`, resHeader);
            // console.log('THUNK', data)
            dispatch(getBuilderCategories(data))
        } catch (err) {
            console.log(err)
        }
    }
}

export const addBuilderCategoryThunk = (data) => {
    return async (dispatch) => {
        try {
            const res = await axios.post(`${serverUrl}/api/builder/categories`, data, resHeader);
            if (!res.data.success) throw 'Error'

            dispatch(addBuilderCategory(res.data.newCategory))

            console.log('addBuilderCategoryThunk', res.data);
        } catch (err) {
            console.log(err);
        }
    }
}

export const updateBuilderCategoryThunk = (data) => {
    return async (dispatch) => {
        try {
            const res = await axios.put(`${serverUrl}/api/builder/categories/${data.category._id}`, data, resHeader)
            dispatch(updateBuilderCategory(data.category));
            // console.log(res.data)
        } catch (err) {
            console.log(err);
        }
    }
}

export const deleteBuilderCategoryThunk = (id) => {
    return async (dispatch) => {
        try {
            const res = await axios.delete(`${serverUrl}/api/builder/categories/${id}/?${tele.initData}`, resHeader);
            dispatch(deleteBuilderCategory(id))
            // console.log('deleteBuilderCategoryThunk', res.data)
        } catch (err) {
            console.log(err);
        }
    }
}



