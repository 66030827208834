import React, { useState } from 'react';
import UserOrderItem from '../UserOrderItem/UserOrderItem';
import { Button } from '@mui/material';

function UserOrdersCard({ orders, tele }) {

	const [viewItems, setViewItems] = useState(false);

	const toggleOrderItems = (idx) => {
		const orderItemsElement = document.querySelectorAll('.order_items');

		// if(viewItemsLinkElement[idx].style.display !== 'none') viewItemsLinkElement[idx].style.display = 'none' 
		// else viewItemsLinkElement[idx].style.display = 'block'

		if (orderItemsElement[idx].style.display === 'none') orderItemsElement[idx].style.display = 'block';
		else orderItemsElement[idx].style.display = 'none';
		setViewItems(!viewItems);
	}

	const formatOrderDate = (date) => {
		let oldHour = new Date(date).getHours();
		const twelveHourFormat = {hour: null, timeOfDay: null};

		if (oldHour >= 12) {
			twelveHourFormat.timeOfDay = 'p.m.'
			if (oldHour > 12) twelveHourFormat.hour = oldHour - 12;
			else twelveHourFormat.hour = oldHour
		} else {
			twelveHourFormat.timeOfDay = 'a.m.'
			if (oldHour === 0) twelveHourFormat.hour = 12
			else twelveHourFormat.hour = oldHour
		}

		let {hour, timeOfDay} = twelveHourFormat;
		let minutes = new Date(date).getMinutes();
		minutes = minutes < 10 ? '0' + String(minutes) : minutes;

		let month = new Date(date).getMonth() + 1;
		let day = new Date(date).getDate();
		let year = new Date(date).getFullYear();


		return `${month}/${day}/${year} ${hour}:${minutes} ${timeOfDay}`
	}

	return (
		<div>
			{
				orders.map((order, idx) => (
					<div key={idx} className='user_orders_card' style={{ display: 'flex', flexDirection: 'column' }}>
						<span>Order #{order.orderNumber} | Status: {order.status} {(order.status === 'unpaid' ? <button onClick={() => tele.openInvoice(order.invoiceLink)}>Pay</button> : '')}</span>
						<span>{formatOrderDate(order.orderDate)}</span>
						<span>There are {order.cart.filter(item => item.type !== 'tax').length} items in this order</span>

						<div className='order_items' style={{ display: 'none' }}>
							<UserOrderItem order={order} />
						</div>

						<span>Total Price: ${(order.totalPrice * 1.08875).toFixed(2)}</span>
						<Button onClick={() => toggleOrderItems(idx)}>{(!viewItems) ? 'View Items' : 'Hide items'}</Button>
					</div>
				))
			}
		</div>
	)
}

export default UserOrdersCard