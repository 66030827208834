import axios from "axios";
import { resHeader, serverUrl, tele } from "../lib";

export const GET_MENU_ITEMS = 'GET_MENU_ITEMS';
export const ADD_MENU_ITEM = 'ADD_MENU_ITEM';
export const UPDATE_MENU_ITEM = 'UPDATE_MENU_ITEM';
export const DELETE_MENU_ITEM = 'DELETE_MENU_ITEM';

//Action creators
export const getMenuItems = (items) => {
    return {
        type: GET_MENU_ITEMS,
        payload: items
    }
}

export const addMenuItem = (item) => {
    return {
        type: ADD_MENU_ITEM,
        payload: item
    }
}

export const updateMenuItem = (item) => {
    return {
        type: UPDATE_MENU_ITEM,
        payload: item
    }
}

export const deleteMenuItem = (id) => {
    return {
        type: DELETE_MENU_ITEM,
        payload: id
    }
}

//Thunk creators

export const fetchMenuItemsThunk = () => {
    return async (dispatch) => {
        try {
            const { data } = await axios.get(`${serverUrl}/api/menu/items/?${tele.initData}`, resHeader);
            //console.log('THUNK', data)
            dispatch(getMenuItems(data))
        } catch (err) {
            console.log(err)
        }
    }
}

export const addMenuItemThunk = (data) => {
    return async (dispatch) => {
        try {
            const res = await axios.post(`${serverUrl}/api/menu/items`, data, resHeader);
            if (!res.data.success) throw 'Error'

            dispatch(addMenuItem(data.item))

            //console.log('addMenuItemThunk', res.data);
        } catch (err) {
            console.log(err);
        }
    }
}

export const updateMenuItemThunk = (data) => {
    return async (dispatch) => {
        try {
            const res = await axios.put(`${serverUrl}/api/menu/items/${data.item._id}`, data, resHeader)
            dispatch(updateMenuItem(data.item));
            //console.log(res.data)
        } catch (err) {
            console.log(err);
        }
    }
}

export const deleteMenuItemThunk = (id) => {
    return async (dispatch) => {
        try {
            const res = await axios.delete(`${serverUrl}/api/menu/items/${id}/?${tele.initData}`, resHeader);
            dispatch(deleteMenuItem(id))
            //console.log('deleteMenuItemThunk', res.data)
        } catch (err) {
            console.log(err);
        }
    }
}



