import React, { useState, useEffect } from 'react';
import { Route, Routes } from 'react-router-dom';
import './App.css';
import { useSelector, useDispatch } from 'react-redux';
import { addToSandwichAction, removeFromSandwichAction } from './actions/sandwich';

import Shop from './Components/Shop/Shop';
import Admin from './Components/Admin/Admin';
import Tos from './Components/Tos/Tos';
import { appName, resHeader } from './lib';
import UserOrders from './Components/UserOrders/UserOrders';
import Orders from './Components/Orders/Orders';
import MenuItems from './Components/MenuItems/MenuItems';
import MenuItemPortal from './Components/MenuItemPortal/MenuItemPortal';
import '@fontsource/roboto'
import SingleMenuItem from './Components/SingleMenuItem/SingleMenuItem';
import MenuCategoriesPortal from './Components/MenuCategoriesPortal/MenuCategoriesPortal';
import BuilderItemsPortal from './Components/BuilderItemsPortal/BuilderItemsPortal';
import axios from 'axios';
import { serverUrl, tele } from './lib';
import { fetchBuilderCategoriesThunk } from './actions/builderCategories';



function App() {

	const userData = tele.initData
	const { sandwich, cart, builderCategories } = useSelector(state => state);
	const dispatch = useDispatch();

	const [primaryItems, setPrimaryItems] = useState({
		meat: false,
		cheese: false
	});

	useEffect( () => {
		dispatch(fetchBuilderCategoriesThunk())
	}, [])

	useEffect(() => {
		setPrimaryItems(primaryItemsChecker(sandwich.sandwichItems))
		// console.log('CART ITEMS app.js', sandwich)
	}, [sandwich.sandwichItems])

	useEffect(() => {
		tele.ready();
		tele.expand();
	})

	const onAdd = (item) => {
		dispatch(addToSandwichAction(item));

		tele.MainButton.hide()
		//document.querySelector('#checkout').style.display = 'flex'
	}

	const onRemove = (item) => {
		dispatch(removeFromSandwichAction(item));

		tele.MainButton.hide()
	}

	const onCheckout = () => {
		if (cart.length > 0) {
			tele.MainButton.text = 'Pay'
			tele.MainButton.show();

			tele.MainButton.onClick(async () => {
				try {
					const { invoiceLink, orderInfo } = await sendDataToBot({ userData, cart });
					tele.openInvoice(invoiceLink);

					tele.onEvent('invoiceClosed', async (result) => {
						const { url, status } = result
						console.log(result)
						if (status === 'paid' && url === invoiceLink) {
							const { data } = await axios.post(`${serverUrl}/api/orders`, { userData, orderInfo }, resHeader);
							window.localStorage.removeItem('cart');
							tele.close();
						}
					});

					// console.log('DATA =>', invoiceLink);
				} catch (err) {
					console.log(err);
				}
			});
		} else {
			alert('Cart is empty!')
		}

	}

	const sendDataToBot = async (data) => {
		try {
			const res = await axios.post(`${serverUrl}/api/invoice`, data, resHeader)
			// console.log(res.data)
			return res.data;
		} catch (err) {
			console.log(err);
		}
	}

	//Checks for meat and cheese in the sandwich to make sure only one is added at a time
	const primaryItemsChecker = (sandwichItems) => {
		const primaryCount = {}
		const primaryItemsKeys = Object.keys(primaryItems)

		// const primaryItemsKeys = builderCategories.filter(category => category.primaryItem === true)

		const primaryItemsCopy = { ...primaryItems } //store a copy of primaryItems state to mutate freely

		//Look for primary items in list of selected toppings and update counter
		sandwichItems.forEach(item => {
			if (primaryItemsKeys.includes(item.type)) {
				(primaryCount[item.type]) ? primaryCount[item.type]++ : primaryCount[item.type] = 1;
			}
		})

		//Check if any primary items were found in the by checking the counter, if found set to primaryItem[itemType] to true
		primaryItemsKeys.forEach(key => {
			if (primaryCount[key] > 0) {
				if (primaryItemsCopy[key] === false) {
					primaryItemsCopy[key] = true;
				}
			} else {
				primaryItemsCopy[key] = false;
			}
		});

		return primaryItemsCopy
	}

	return (
		<div className="App">
			<h1 className='heading'>{appName}</h1>
			<Routes>
				<Route path='/' element={<Shop primaryItems={primaryItems} onCheckout={onCheckout} onAdd={onAdd} onRemove={onRemove} />} />
				<Route path='/orders' element={<UserOrders />} />
				<Route exact path='/menu' element={<MenuItems />} />
				<Route path='/menu/:itemId' element={<SingleMenuItem />} />
				<Route exact path='/admin' element={<Admin />} />
				<Route path='/admin/builder' element={<BuilderItemsPortal />} />
				<Route path='/admin/builder/categories' element={<MenuCategoriesPortal categoryType={'builder'} />} />
				<Route path='/admin/menu' element={<MenuItemPortal />} />
				<Route path='/admin/menu/categories' element={<MenuCategoriesPortal categoryType={'menu'} />} />
				<Route path='/admin/orders' element={<Orders />} />
				<Route path='/tos' element={<Tos />} />
			</Routes>
		</div>
	);
}

export default App;
