
const initialState = [];

const cartReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'SET_CART':
            return action.payload;
        case 'ADD_TO_CART':
            return [...state, action.payload];
        case 'REMOVE_FROM_CART':
            return state.filter((item, idx) => idx !== action.payload);
        case 'UPDATE_CART_ITEM':
            {
                const { item, price } = action.payload;
                return (state.length) ? [...state, { ...item, price }] : [{ ...item, price }];
            }
        case 'CLEAR_CART':
            return [];
        default:
            return state;
    }
}

export default cartReducer;