import { combineReducers } from "redux";
import itemsReducer from "./items";
import sandwichReducer from "./sandwich";
import cartReducer from "./cart";
import menuItemsReducer from "./menuItems";
import menuCategoriesReducer from "./menuCategories";
import builderCategoriesReducer from "./builderCategories";

const allReducers = combineReducers({
    items: itemsReducer,
    menuItems: menuItemsReducer,
    sandwich: sandwichReducer,
    cart: cartReducer,
    menuCategories: menuCategoriesReducer,
    builderCategories: builderCategoriesReducer
});

export default allReducers;