import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import BuilderItemCard from '../BuilderItemCard/BuilderItemCard';
import './BuilderItemsList.css'
import { fetchBuilderCategoriesThunk } from '../../actions/builderCategories';


function BuilderItemsList({ setCurrentItem }) {

    const dispatch = useDispatch();
    const {items, builderCategories} = useSelector(state => state);

    const builderItemTypes = ['meat', 'cheese', 'produce', 'condiment'];

    useEffect(() => {
        dispatch(fetchBuilderCategoriesThunk());
        console.log(items)
    }, []);

    //Each card should bring up the ItemForm 
    const editItem = (item) => {
        setCurrentItem(item);
    }



    return (
        <div className='portal-items'>
            {
                builderCategories.map(category => <BuilderItemCard editItem={editItem} itemType={category.name} />)
            }
        </div>
    )
}

export default BuilderItemsList