import axios from "axios";
import { resHeader, serverUrl, tele } from "../lib";

//Actions
const GET_ITEMS = 'GET_ITEMS';
const ADD_ITEM = 'ADD_ITEM';
const DELETE_ITEM = 'DELETE_ITEM';
const UPDATE_ITEM = 'UPDATE_ITEM';


//Action creators
export const getItems = (items) => {
    return {
        type: GET_ITEMS,
        payload: items
    }
}

export const addItem = (item) => {
    return {
        type: ADD_ITEM,
        payload: item
    }
}

export const deleteItem = (id) => {
    return {
        type: DELETE_ITEM,
        payload: id
    }
}

export const updateItem = (item) => {
    return {
        type: UPDATE_ITEM,
        payload: item
    }
}

//Thunk Creators
export const fetchItemsThunk = () => {
    return async (dispatch) => {
        try {
            const { data } = await axios.get(`${serverUrl}/api/builder/items/?${tele.initData}`, resHeader);
            // console.log('THUNK', data)
            dispatch(getItems(data))
        } catch (err) {
            console.log(err)
        }
    }
}

export const addItemThunk = (data) => {
    return async (dispatch) => {
        try {
            const res = await axios.post(serverUrl + '/api/builder/items', data, resHeader);
            if (!res.data.success) throw 'Error'

            dispatch(addItem(data.item))

            // console.log('addItemThunk', res.data);
        } catch (err) {
            console.log(err);
        }
    }
}

export const deleteItemThunk = (id) => {
    return async (dispatch) => {
        try {
            // const res = 
            await axios.delete(`${serverUrl}/api/builder/items/${id}/?${tele.initData}`, resHeader);
            dispatch(deleteItem(id))
            // console.log('deleteItemThunk', res.data)
        } catch (err) {
            console.log(err);
        }
    }
}

export const updateItemThunk = (data) => {
    return async (dispatch) => {
        try {
            await axios.put(serverUrl + '/api/builder/items/', data, resHeader)
            dispatch(updateItem(data.item));
        } catch (err) {
            console.log(err);
        }
    }
}