import React from 'react'
import './Button.css'

function Button({ id, className, type, title, disable, onClick, hide, style }) {
	return (
		<button className={`btn ${className} ${(type === 'add' && 'add') ||
			(type === 'remove' && 'remove') ||
			(type === 'checkout' && 'checkout')
			}`}
			id={(id) ? id : ''}
			disabled={disable}
			style={(hide) ? { ...style, display: 'none' } : style}
			onClick={onClick}
		>
			{title}
		</button>
	)
}

export default Button