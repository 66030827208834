import React, { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux';
import { addItemThunk } from '../../actions/items';
import BuilderItemForm from '../BuilderItemForm/BuilderItemForm';
import './AddBuilderItem.css'

function AddBuilderItem({setPortal}) {

	const dispatch = useDispatch();

	const [formData, setFormData] = useState({
		name: '',
		type: '',
		imageUrl: '',
		price: 0
	})

	const tele = window.Telegram.WebApp;

	//Handle form submission and send to appropriate channels
	const handleForm = async (e) => {
		e.preventDefault();

		try {
			dispatch(addItemThunk({
				userData: tele.initData || '',
				item: formData
			}));

			console.log('TEST', formData)
			tele.showAlert('Item added to inventory');
			const formFields = Object.keys(formData);
			formFields.forEach(field => {
				//setFormData({...formData, [field]: ''});
				document.getElementsByName(field)[0].value = '';
			});

			setPortal('edit');
		} catch (err) {
			tele.showAlert('Oops, there was a problem. Try again.');
			console.log(err);
		}
		console.log('Submitted');
	}

	return (
		<div className='item_portal'>
			<h1>Add Item</h1>

			<BuilderItemForm handleForm={handleForm} formData={formData} setFormData={setFormData} />
		</div>
	)
}

export default AddBuilderItem