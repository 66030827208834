import { OutlinedInput, Select, MenuItem as Option } from '@mui/material';
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import { tele } from '../../lib';
import './MenuItemForm.css'

function MenuItemForm(props) {
    const {
        formData,
        setFormData,
        handleForm,
        item,
    } = props;

    const itemExists = item !== undefined;
    const { menuCategories } = useSelector(state => state);
    const [variantAmount, setVariantAmount] = useState((itemExists) ? item.variants.length : 0); //Keeps count of variant amounts
    const [variantInputs, setVariantInputs] = useState([]);
    const [populated, setPopulated] = useState(true);
    const [remove, setRemove] = useState({ status: false, variantIndex: 0 });
    const [clear, setClear] = useState({ status: false, ev: undefined });

    const inputStyle = {
        backgroundColor: 'white',
        paddingLeft: '5px'
    }

    useEffect(() => {
        if (item !== undefined) addItemToForm();
        initializeVariants();
    }, []);

    // useEffect(() => {
    //     //If form was not populated, and item exists for editing and there are variants in the form, then populate
    //     //the variantInput
    //     if ((!populated && itemExists && formData.variants.length > 0)) {
    //         for (let i = 1; i <= formData.variants.length; i++) {
    //             populateVariantInput(i)
    //         }
    //         setPopulated(true); //Set populated flag to true
    //     }
    //     console.log('INPUTS LENGTH => ', variantInputs.length)
    // }, [variantInputs]);

    useEffect(() => {
        //If form was not populated, and item exists for editing and there are variants in the form, then populate
        //the variantInput
        if (!populated && itemExists && formData.variants.length > 0) {
            for (let i = 1; i <= item.variants.length; i++) {
                populateVariantInput(i);
            }
            setPopulated(true); //Set populated flag to true
        }
    }, [populated])

    useEffect(() => console.log('VARIANT AMT =>', variantAmount), [variantAmount]);

    useEffect(() => {
        if (clear.ev) {
            handleForm(clear.ev, setClear);
        } else if (clear.status) {
            clearForm();
        }
    }, [clear])

    useEffect(() => {
        if (remove.status) {
            removeVariant();
        }
    }, [remove])

    useEffect(() => {
        //When formData changes, if remove flag is true then we need to populate the variantInputs
        if (remove.status) {
            for (let i = 1; i <= formData.variants.length; i++) {
                populateVariantInput(i)
            }
            setRemove({ status: false, variantIndex: 0 });
        }
        console.log('FORM DATA =>', formData.variants)
    }, [formData]);

    //returns variantInputs elements for rendering
    const createVariantInputs = (variantIndex) => {
        return (
            <div key={variantIndex} className='variant_box'>
                <div className='variant_inputs'>
                    {/* <input type="text" name={`variant${variantIndex}_type`} placeholder="Variant Type (e.g, small, large, etc)" />
                    <input type="text" name={`variant${variantIndex}_price`} placeholder="Variant Price (e.g 5.99, 7.99, etc)" /> */}
                    <OutlinedInput
                        size='small'
                        name={`variant${variantIndex}_type`}
                        placeholder="Variant Type (e.g, small, large, etc)"
                        sx={inputStyle}
                    />

                    <OutlinedInput
                        size='small'
                        name={`variant${variantIndex}_price`}
                        placeholder="Variant Price (e.g 5.99, 7.99, etc)"
                        sx={inputStyle}
                    />
                </div>
                <br />
            </div>
        )
    }

    //Initializes the necessary states. Creates and renders variantInputs
    const initializeVariants = () => {
        //If and item exists for editing, set the formData variants...
        if (item !== undefined) {
            const variantObjects = item.variants.map(variant => {
                return {
                    variantType: variant.variantType,
                    variantPrice: variant.variantPrice
                }
            })
            setFormData({ ...item, variants: variantObjects });

            //...and creates as many variantInputs necessary to populate
            const inputs = [];
            for (let i = 1; i <= variantAmount + 1; i++) { // + 1 to initially create an extra empty set of inputs
                inputs.push(createVariantInputs(i));
            }
            setVariantInputs(inputs);
            // setVariantAmount(variantAmount + 1);
        } else {
            //else, just create a single set of variantInputs (expected to be empty)
            addVariantInputs();
        }
    }

    //Appends a set of variantInputs
    const addVariantInputs = () => {
        //If variantInputs exists,...
        if (variantInputs.length > 0) {
            //Check them...
            const { notEmpty, variantType, variantPrice } = checkVariants(variantInputs.length);

            //if they have data...
            if (notEmpty) {
                //...Add new variant to formData.variants
                setFormData({
                    ...formData,
                    variants: [
                        ...formData.variants,
                        {
                            variantType: variantType.value,
                            variantPrice: Number(variantPrice.value)
                        }
                    ]
                });

                //...Append a new set of variantInputs
                setVariantInputs([
                    ...variantInputs,
                    createVariantInputs(variantInputs.length + 1)
                ]);

                //...Increment the variantAmoutn by 1
                setVariantAmount(variantAmount + 1);

                //...Disable the newly filled set of variantInputs
                variantType.setAttribute('disabled', '');
                variantPrice.setAttribute('disabled', '');

                //...Render a removeButton for the newly filled et
                createRemoveButton(variantInputs.length);

            } else {
               (tele.initData) ? tele.showAlert('Variant fields cannot be empty') :  alert('Variant fileds cannot be empty')
            }
        } else {
            //...else, create a new empty set of variantInputs
            setVariantInputs([createVariantInputs(1)]);
        }
    }

    //Checks a set of variant inputs and returns whether they are empty, access to the elements and their values
    const checkVariants = (variantIndex) => {
        const variantTypeElem = document.getElementsByName(`variant${variantIndex}_type`)[0];
        const variantPriceElem = document.getElementsByName(`variant${variantIndex}_price`)[0];
        const variantElementsExist = variantTypeElem !== undefined && variantPriceElem !== undefined;
        const variantElementsNotEmpty = variantElementsExist ? (variantTypeElem.value !== '' && variantPriceElem.value !== '') : false;

        console.log('checkVariants',)
        return {
            notEmpty: variantElementsNotEmpty,
            variantType: variantElementsExist ? variantTypeElem : { value: undefined },
            variantPrice: variantElementsExist ? variantPriceElem : { value: undefined }
        };
    }

    //Populates a variantInput with the corresponding formData variant while disabling the inputs and adding a remove button
    const populateVariantInput = (variantIndex) => {
        const { variantType, variantPrice } = checkVariants(variantIndex);

        if (variantType.value !== undefined && variantType.value !== undefined) {
            if (formData.variants[variantIndex - 1] !== undefined) {
                variantType.value = formData.variants[variantIndex - 1].variantType;
                variantPrice.value = formData.variants[variantIndex - 1].variantPrice.toFixed(2);

                variantType.setAttribute('disabled', '');
                variantPrice.setAttribute('disabled', '');
                createRemoveButton(variantIndex);
            }
        }

    }

    //Creates a 'button' node, sets its attributes and inserts it into the proper element
    const createRemoveButton = (variantIndex) => {
        const removeButton = document.createElement('button');
        removeButton.id = `remove${variantIndex}`;
        removeButton.type = 'button'
        removeButton.addEventListener('click', () => triggerRemove(variantIndex))
        removeButton.innerHTML = 'Remove'

        const currentInputs = document.getElementsByClassName('variant_box')[variantIndex - 1];
        if (currentInputs.children[1].type !== 'button') currentInputs.insertBefore(removeButton, currentInputs.children[currentInputs.children.length - 1]);
    }

    //Clears variantInputs and creates a new set with one less than before
    const triggerRemove = (variantIndex) => {
        setRemove({ status: true, variantIndex }); //Set remove flag to true
    }

    const removeVariant = () => {
        const newInputs = [];
        for (let i = 1; i <= variantInputs.length - 1; i++) {
            clearVariantInputs(i);
            newInputs.push(createVariantInputs(i));
        }
        setVariantInputs(newInputs);
        //Update formData to reflect the deletion of a variant
        setFormData({
            ...formData,
            variants: formData.variants.filter((variant, idx) => remove.variantIndex - 1 !== idx)
        })
    }

    //Clears a set of variantInputs, re-enables them and removes any removeButton that exists
    const clearVariantInputs = (variantIndex) => {
        const { notEmpty, variantType, variantPrice } = checkVariants(variantIndex);
        if (notEmpty) {
            variantType.value = ''
            variantPrice.value = ''
            variantType.removeAttribute('disabled');
            variantPrice.removeAttribute('disabled');
            const removeButton = document.getElementById(`remove${variantIndex}`);
            document.getElementsByClassName('variant_box')[variantIndex - 1].removeChild(removeButton);
        }
    }

    //Adds non-variant formData to the form
    const addItemToForm = () => {
        const fields = ['name', 'description', 'category'];

        fields.forEach(field => {
            const element = (field !== 'description') ? document.getElementsByName(field)[0] : document.getElementsByName(field)[1];

            if (element !== undefined) {
                if (field === 'imageUrl') {
                    // console.log('KEY', field, item.imageUrl);
                    element.value = item.imageUrl ? item.imageUrl : 'https://via.placeholder.com/100.png';
                } else {
                    // console.log('KEY', field);
                    element.value = item[field];
                }
            }
            console.log(field)
        });
        setPopulated(false);
    }

    //Clears the entire form
    const clearForm = () => {
        const formFields = Object.keys(formData);
        formFields.forEach(field => {
            if (field !== 'variants') {
                setFormData({ ...formData, [field]: '' });

                if (field === 'description') document.getElementsByName(field)[1].value = '';
                // else document.getElementsByName(field)[1].value = '';
            } else {
                setFormData({ ...formData, [field]: [] })
            }

        });

        const inputElements = document.getElementsByTagName('input');
        for (let i = 0; i < inputElements.length; i++) {
            inputElements[i].value = '';
        }

        setVariantAmount(1);
        setVariantInputs([createVariantInputs(1)]);
        setPopulated(false);
        setClear({ status: false });
    }

    //Updates formData state as user types
    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    }

    return (
        <div className='form_container'>
            <form id="item_form" action="" onSubmit={(ev) => setClear({ ...clear, ev })}>
                <div>
                    {/* <img src={formData.imageUrl ? formData.imageUrl : 'https://via.placeholder.com/100.png'} alt={formData.name} width="200px" /> */}
                    <label htmlFor="name">Name</label>
                    <OutlinedInput
                        size='small'
                        type={'text'}
                        name={'name'}
                        onChange={handleChange}
                        sx={inputStyle}
                    />

                    <label htmlFor="description">Description</label>
                    <OutlinedInput
                        type={'text'}
                        name={'description'}
                        onChange={handleChange}
                        sx={inputStyle}
                        rows={3}
                        multiline
                    />

                    <label htmlFor="category">Category</label>
                    <Select
                        name={'category'}
                        variant='outlined'
                        value={formData.category}
                        size='small'
                        sx={{paddingLeft: '20px', backgroundColor: 'white'}}
                        onChange={handleChange}
                        displayEmpty
                    >
                        <Option value="">Choose item type</Option>
                        {
                            menuCategories.map(({ name }, idx) => {
                                return <Option key={idx} value={name}>{name[0].toUpperCase() + name.slice(1, name.length)}</Option>
                            })
                        }
                    </Select>

                    <div id='variants'>
                        <label htmlFor=''>Variants</label>
                        {
                            variantInputs.map(variantInput => (variantInput))
                        }
                        <button id='add_variant' className='btn' type='button' onClick={addVariantInputs} style={{ width: '50%' }}>+</button>
                    </div>

                    {/* <label htmlFor="imageUrl">Image</label>
                    <input type={'text'} name={'imageUrl'} placeholder={'Link to Image'} onChange={handleChange} /> */}
                    <br />
                    <button className='btn' type='submit'>
                        Submit Item
                    </button>

                </div>
            </form>
            {/* <form id="item_form" action="" onSubmit={(ev) => setClear({ ...clear, ev })}>
                <div>
                    <img src={formData.imageUrl ? formData.imageUrl : 'https://via.placeholder.com/100.png'} alt={formData.name} width="200px" />
                    <label htmlFor="name">Name</label>
                    <input type={'text'} name={'name'} onChange={handleChange} />

                    <label htmlFor="description">Description</label>
                    <textarea type={'text'} name={'description'} onChange={handleChange} />

                    <label htmlFor="category">Category</label>
                    <select name={'category'} onChange={handleChange}>
                        <option value="">Choose item type</option>
                        {
                            menuCategories.map(({ name }, idx) => {
                                return <option key={idx} value={name}>{name[0].toUpperCase() + name.slice(1, name.length)}</option>
                            })
                        }
                    </select>

                    <div id='variants'>
                        <label htmlFor=''>Variants</label>
                        {
                            variantInputs.map(variantInput => (variantInput))
                        }
                        <button id='add_variant' className='btn' type='button' onClick={addVariantInputs} style={{ width: '50%' }}>+</button>
                    </div>

                    <label htmlFor="imageUrl">Image</label>
                    <input type={'text'} name={'imageUrl'} placeholder={'Link to Image'} onChange={handleChange} />
                    <br />
                    <button className='btn' type='submit'>
                        Submit Item
                    </button>

                </div>
            </form> */}
        </div>
    )
}

export default MenuItemForm