import React, { useState, useEffect } from 'react'
// import Button from '../Button/Button';
import AddBuilderItem from '../AddBuilderItem/AddBuilderItem';
import EditBuilderItem from '../EditBuilderItem/EditBuilderItem';
import './Admin.css'
import { useSelector, useDispatch } from 'react-redux';
import { fetchItemsThunk } from '../../actions/items';
import { useNavigate } from 'react-router-dom';
import { Button, IconButton } from '@mui/material';
import { ArrowBack } from '@mui/icons-material';

function Admin() {
    const navigate = useNavigate();

    return (
        <div className='admin_container'>
            <span>Admin Portal</span>
            <div className='admin_btn_container'>
                <span>Inventory Management</span>
                <Button variant='contained' onClick={() => navigate('/admin/builder')}>
                    Builder Items
                </Button>

                <Button variant='contained' onClick={() => navigate('/admin/builder/categories')}>
                    Builder Categories
                </Button>

                <br />

                <Button variant='contained' onClick={() => navigate('/admin/menu')}>
                    Menu Items
                </Button>

                <Button variant='contained' onClick={() => navigate('/admin/menu/categories')}>
                    Menu Categories
                </Button>

            </div>
        </div>
    )
}

export default Admin