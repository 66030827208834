
const ADD_TO_SANDWICH = 'ADD_TO_SANDWICH';
const REMOVE_FROM_SANDWICH = 'REMOVE_FROM_SANDWICH';
const SET_SANDWICH_TEMP = 'SET_SANDWICH_TEMP';
const SET_BREAD_TYPE = 'SET_BREAD_TYPE';
const CLEAR_SANDWICH = 'CLEAR_SANDWICH'

export const setBreadTypeAction = (type) => {
    return {
        type: SET_BREAD_TYPE,
        payload: type
    }
}

export const setSandwichTempAction = (temp) => {
    return {
        type: SET_SANDWICH_TEMP,
        payload: temp
    }
}

export const addToSandwichAction = (item) => {
    return {
        type: ADD_TO_SANDWICH,
        payload: item
    }
}

export const removeFromSandwichAction = (item) => {
    return {
        type: REMOVE_FROM_SANDWICH,
        payload: item
    }
}

export const clearSandwichAction = () => {
    return {
        type: CLEAR_SANDWICH
    }
}



