import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import EditCategoryCard from '../EditCategoryCard/EditCategoryCard'
import MenuCategoryForm from '../MenuCategoryForm/MenuCategoryForm'
import { fetchMenuCategoriesThunk } from '../../actions/menuCategories'
import './MenuCategoriesPortal.css'
import { IconButton } from '@mui/material'
import { ArrowBack } from '@mui/icons-material'
import { useNavigate } from 'react-router-dom'
import { fetchBuilderCategoriesThunk } from '../../actions/builderCategories'

function MenuCategoriesPortal({categoryType}) {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { menuCategories, builderCategories } = useSelector(state => state);
    const [edit, setEdit] = useState({ status: false, id: null });
    const [categories, setCategories] = useState([]);

    useEffect(() => {
        try {
            if(categoryType === 'menu'){
                dispatch(fetchMenuCategoriesThunk());
            }
            
            if(categoryType === 'builder'){
                dispatch(fetchBuilderCategoriesThunk()); 
            }
        } catch (err) {
            console.log('error');
        }
    }, []);

    useEffect(() => setCategories(builderCategories), [builderCategories]);
    useEffect(() => setCategories(menuCategories), [menuCategories]);

    return (
        <div className='categories_portal'>
            <div style={{textAlign: 'left', width: '100%'}}>
                <IconButton onClick={() => navigate('/admin')}>
                    <ArrowBack /><span>Back</span>
                </IconButton>
            </div>

            {
                !edit.status ? <MenuCategoryForm categoryType={categoryType} /> : ''
            }

            <div style={{ display: 'flex', flexDirection: 'column', rowGap: '10px', width: '90%' }}>
                {
                    categories.map((category, idx) => <EditCategoryCard key={idx} categoryType={categoryType} category={category} edit={edit} setEdit={setEdit} />)
                }
            </div>
        </div>
    )
}

export default MenuCategoriesPortal