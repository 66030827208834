import { ADD_BUILDER_CATEGORY, DELETE_BUILDER_CATEGORY, GET_BUILDER_CATEGORIES, UPDATE_BUILDER_CATEGORY } from "../actions/builderCategories";

const builderCategoriesReducer = (state = [], action) => {
    switch (action.type) {
        case GET_BUILDER_CATEGORIES:
            return action.payload;
        case ADD_BUILDER_CATEGORY:
            return [...state, action.payload];
        case UPDATE_BUILDER_CATEGORY:
            const updatedCategories = state.map(category => (category._id === action.payload._id) ? action.payload : category);
            return updatedCategories;
        case DELETE_BUILDER_CATEGORY:
            return state.filter(category => category._id !== action.payload);
        default:
            return state;
    }
}

export default builderCategoriesReducer;