//Items reducer

const initialState = {
  breadType: '',
  sandwichTemp: '',
  sandwichItems: []
}

export const sandwichReducer = (state = initialState, action) => {
  
    switch(action.type){
        case 'SET_BREAD_TYPE':
            return {...state, breadType: action.payload}
        case 'SET_SANDWICH_TEMP':
            return {...state, sandwichTemp: action.payload}
        case 'ADD_TO_SANDWICH':
          {
            const item = action.payload;
            const {sandwichItems} = state;
            const exist = sandwichItems.find((sandwichItem) => sandwichItem._id === item._id);

            if(exist){
              return {...state, sandwichItems: sandwichItems.map(sandwichItem => sandwichItem._id === item._id ? {...exist, quantity: exist.quantity + 1} : sandwichItem )};
            }else{
              return {...state, sandwichItems: [...sandwichItems, {...item, quantity: 1}]}
            }
          }
        case 'REMOVE_FROM_SANDWICH':
          {
            const item = action.payload
            const {sandwichItems} = state;
            const exist = sandwichItems.find((sandwichItem) => sandwichItem._id === item._id);

            if(exist.quantity === 1){
              return {...state, sandwichItems: sandwichItems.filter(sandwichItem => sandwichItem._id !== item._id)};
            }else{
              return {...state, sandwichItems: sandwichItems.map(sandwichItem => sandwichItem._id === item._id ? {...exist, quantity: exist.quantity - 1} : sandwichItem)};
            }
          }
        case 'CLEAR_SANDWICH':
          return {
            breadType: '',
            sandwichTemp: '',
            sandwichItems: []
          }
        default:
            return state
    }
}

export default sandwichReducer;