import React from 'react'
import { useDispatch } from 'react-redux'
import { deleteMenuItemThunk } from '../../actions/menuItems';
import './EditMenuItemCard.css'


function EditMenuItemCard({ item, setCurrentItem, setEdit }) {

    const dispatch = useDispatch();

    const editMenuItem = () => {
        setEdit(true);
        setCurrentItem(item);
    };

    return (
        <div className='edit_menu_item_card item-card'>
            <span>{item.name}</span>
            <div className='item_card_buttons'>
                <button onClick={() => editMenuItem()}>Edit</button>
                <button onClick={() => dispatch(deleteMenuItemThunk(item._id))}>Delete</button>
            </div>
        </div>)

}

export default EditMenuItemCard