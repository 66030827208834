import React, { useEffect, useState } from 'react'
import AddMenuItem from '../AddMenuItem/AddMenuItem'
import EditMenuItems from '../EditMenuItems/EditMenuItems'
import { Button, IconButton } from '@mui/material'
import './MenuItemPortal.css'
import { useDispatch } from 'react-redux'
import { fetchMenuCategoriesThunk } from '../../actions/menuCategories'
import { ArrowBack } from '@mui/icons-material'
import { useNavigate } from 'react-router-dom'

function MenuItemPortal() {
    const [portal, setPortal] = useState('');
    const dispatch = useDispatch();
    const navigate = useNavigate();

    useEffect(() => {
        dispatch(fetchMenuCategoriesThunk());
    }, []);

    const togglePortal = (portal) => {
        switch (portal) {
            case 'add':
                return <AddMenuItem setPortal={setPortal} />
            case 'edit':
                return <EditMenuItems setPortal={setPortal} />
            default:
                return (
                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%' }}>
                        <Button
                            className='menu_portal_btn'
                            type='button'
                            onClick={() => setPortal('add')}
                            variant={'contained'}
                        >
                            Add Menu Item
                        </Button>
                        <br />
                        <Button
                            className='menu_portal_btn'
                            type='button'
                            onClick={() => setPortal('edit')}
                            variant={'contained'}
                        >
                            Edit Menu Item
                        </Button>
                        {/* <button className='btn menu_portal_btn' type='button' onClick={() => setPortal('add')}>Add Menu Item</button>
                        <button className='btn menu_portal_btn'  type='button' onClick={() => setPortal('edit')}>Edit Menu Item</button> */}
                    </div>
                )
        }
    }
    return (
        <div>
            <IconButton onClick={() => navigate('/admin')}>
                <ArrowBack /><span>Back</span>
            </IconButton>
            {
                togglePortal(portal)
            }
        </div>
    )
}

export default MenuItemPortal