export const resHeader = {
        headers: {
            'Bypass-Tunnel-Reminder': '*',
            'ngrok-skip-browser-warning': '*'
    }
}

export const tele = window.Telegram.WebApp;
export const { themeParams } = tele
export const serverUrl = process.env.REACT_APP_SERVER.trim() || '';
export const appName = process.env.REACT_APP_APP_NAME.trim() || '';
// export const serverUrl = 'https://teledeli.us.to';
// export const serverUrl = 'http://localhost:7771';