import React, {useEffect, useState} from 'react'
import Button from '../Button/Button';
import './Card.css'

function Card({item, onAdd, onRemove, primaryItem, added, setAdded}) {
    
    const  [count, setCount] = useState(0);
    const {name, description, imageUrl, price} = item;

    useEffect(() => {
        if(added === true){
            setCount(0);
            setAdded(false);
        }
    } ,[added])

    const handleIncrement = () => {
        setCount(count+1);
        onAdd(item);
    }    
    const handleDecrement = () => {
        setCount(count-1);
        onRemove(item);
    }

    const conditionalDisable = () => {
        if(primaryItem === false){
            if(count < 1){
                return (<Button title={'+'} type={'add'} onClick={handleIncrement} />)
            }
        }
        
        if(primaryItem === undefined){
            if(count < 1){
                return (<Button style={{backgroundColor: '#f8a917 !important'}} title={'+'} type={'add'} onClick={handleIncrement} />)
            }
        }

        

    }

  return (
    <div className='card'>
        <span className={`${count !== 0 ? "card_badge" : "card_badge--hidden"}`}>
            {count}
        </span>
        <div className='image_container'>
            <img src={imageUrl ? imageUrl : 'https://via.placeholder.com/100.png'} alt="" />
        </div>
        <span className='card_title'>
            {name} 
            <br />
            <span className='card_price'>{price ? `$${price.toFixed(2)}` : ''}</span>
        </span>

        {
            description ?<span>{description}</span> : ''
        }

        <div className='btn-container'>
            {
                conditionalDisable()
            }
            { 
                count !== 0 ? (
                    <Button title={'-'} type={'remove'} onClick={handleDecrement} /> 
                    ) : 
                    (
                    ''
                    )
            }
        </div>
    </div>
  )
}

export default Card