import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import axios from 'axios';

import ItemsRow from '../ItemsRow/ItemsRow';
import Cart from '../Cart/Cart';


import { fetchItemsThunk } from '../../actions/items';
import { fetchMenuItemsThunk } from '../../actions/menuItems';

import { resHeader, serverUrl, tele } from '../../lib';
import './Shop.css'
import SandwichBuilder from '../SandwichBuilder/SandwichBuilder';
import MenuItems from '../MenuItems/MenuItems';
import { Box, Button, Fab, IconButton, Modal } from '@mui/material';
import { ArrowBack, ShoppingCart } from '@mui/icons-material';
import { fetchMenuCategoriesThunk } from '../../actions/menuCategories';
import { fetchBuilderCategoriesThunk } from '../../actions/builderCategories';

function Shop({ primaryItems, onCheckout, onAdd, onRemove }) {

	const { cart } = useSelector(state => state);

	const [sandwichPrices, setSandwichPrices] = useState([]);
	const [dashboard, setDashboard] = useState('');
	const [open, setOpen] = useState(false)

	const dispatch = useDispatch();

	useEffect(() => {
		dispatch(fetchItemsThunk());
		dispatch(fetchMenuItemsThunk());
		dispatch(fetchMenuCategoriesThunk());
		dispatch(fetchBuilderCategoriesThunk());
		// console.log('dispatch', items)
		getPrices()
	}, []);

	const getPrices = async () => {
		try {
			const { data } = await axios.get(`${serverUrl}/api/builder/prices/?${tele.initData}`, resHeader)
			setSandwichPrices(data);
		} catch (err) {
			console.log(err);
		}
	}

	const switchDashboard = () => {
		switch (dashboard) {
			case 'builder':
				return (
					<SandwichBuilder
						primaryItems={primaryItems}
						onAdd={onAdd}
						onRemove={onRemove}
						sandwichPrices={sandwichPrices}
					/>
				)
			case 'menu':
				return <MenuItems />
			default:
				return (
					<div className='dashboard_btns'>
						<Button variant='contained' onClick={() => setDashboard('builder')}>Burger Builder</Button>
						<Button variant='contained' onClick={() => setDashboard('menu')}>Menu</Button>
					</div>
				)
		}
	}

	const openCart = () => {
		setOpen(true);
	}

	const closeCart = () => {
		setOpen(false)
	}

	const styles = {
		position: 'fixed',
		bottom: '50px',
		right: '20px',
	}

	return (
		<div id='shop'>
			{
				(dashboard !== '') ? (
					<IconButton sx={{ color: 'gray' }} onClick={() => setDashboard('')}>
						<ArrowBack /><span>Main Menu</span>
					</IconButton>
				) : ''
			}
			{
				switchDashboard()
			}
			<Fab color='primary' style={styles} onClick={openCart}>
				<span
					className={`${cart.reduce((acc, { quantity }) => acc + quantity, 0) !== 0 ? "cart_icon_badge" : "cart_icon_badge--hidden"}`}
				>
					{cart.reduce((acc, { quantity }) => acc + quantity, 0)}
				</span>
				<ShoppingCart />
			</Fab>

			<Cart
				onCheckout={onCheckout}
				open={open}
				closeCart={closeCart}
			/>

		</div>
	)
}

export default Shop