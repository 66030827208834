import { Close } from '@mui/icons-material';
import { Button, IconButton, OutlinedInput } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useDispatch, } from 'react-redux';
import { addMenuCategoryThunk, updateMenuCategoryThunk } from '../../actions/menuCategories';
import { tele, themeParams } from '../../lib';
import './MenuCategoryForm.css';
import { addBuilderCategoryThunk, updateBuilderCategoryThunk } from '../../actions/builderCategories';

function MenuCategoryForm({ categoryType, category, setEdit }) {

	const [newCategory, setNewCategory] = useState('');

	const dispatch = useDispatch();

	useEffect(() => {
		if (category) {
			document.getElementById('edit_category').value = category.name;
			setNewCategory(category.name)
		}
		console.log(categoryType);
	}, [])

	const addCategory = (e) => {
		e.preventDefault();
		try {
			const categoryObj = { name: newCategory }

			if (newCategory.length) {
				if (category) {
					category.name = newCategory

					switch (categoryType) {
						case 'menu':
							dispatch(updateMenuCategoryThunk({ userData: tele.initData, category }));
							break;
						case 'builder':
							dispatch(updateBuilderCategoryThunk({ userData: tele.initData, category }));
							break;
						default:
							break;
					}

					setEdit(false);
				} else {

					switch (categoryType) {
						case 'menu':
							dispatch(addMenuCategoryThunk({ userData: tele.initData, category: categoryObj }));
							break;
						case 'builder':
							dispatch(addBuilderCategoryThunk({ userData: tele.initData, category: categoryObj }));
							break;
						default:
							break;
					}

					document.querySelector('#new_category').value = ''
				}
			}
		} catch (err) {
			console.log('error')
		}
		console.log(newCategory)
	}


	const handleChange = (e) => {
		setNewCategory(e.target.value);
	}

	return (
		<div className='category_form_container'>
			<form action="" onSubmit={addCategory}>
				{
					(category) ?
						(<IconButton sx={{ color: themeParams.text_color }} onClick={() => setEdit(false)}>
							<Close />
						</IconButton>) : ''
				}

				<OutlinedInput
					variant='outlined'
					id={category ? 'edit_category' : 'new_category'}
					onChange={handleChange}
					size='small'
					placeholder={category ? 'Edit Category' : 'New Category'}
					sx={{ backgroundColor: 'white' }}
				/>

				<Button type='submit' variant='outlined' >
					{!category ? 'ADD CATEGORY' : 'EDIT CATEGORY'}
				</Button>
			</form>
			<br />
		</div>
	)
}

export default MenuCategoryForm