import React, { useEffect, useState} from 'react'
import axios from 'axios'

import './UserOrders.css'
import { resHeader, serverUrl, tele } from '../../lib';
import UserOrdersCard from '../UserOrdersCard/UserOrdersCard';
import { useParams } from 'react-router-dom';

function UserOrders({match}) {
    const userDataUnsafe = tele.initDataUnsafe;
    

    const [orders, setOrders] = useState([])
    const { id } = useParams();

    useEffect( () => {
        getUserOrders()
    }, [])

    const getUserOrders = async () => {
        try{
            const {data} = await axios.get(`${serverUrl}/api/orders/${userDataUnsafe.user.id}/?${tele.initData}`, resHeader);
    
            setOrders(data)
        }catch(err){
            console.log('Cannot get orders');
        }
    }

  return (
    <div className='user_orders_container'>
        <h2 className='hint_color'>My Orders</h2>

        {
            orders.length > 0 ? <UserOrdersCard orders={orders}  tele={tele} /> : <span>No orders</span>
        }
    </div>
    
  )
}

export default UserOrders