import React from 'react';
import { HashRouter } from 'react-router-dom';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { Provider } from 'react-redux';
import store from './store';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
	<React.StrictMode>
	<HashRouter>
		<Provider store={store}>
			<App />
			<div className="footer" style={{marginTop: '60px'}}>
				<span>Powered By <a href="https://t.me/TeleDeli_bot"><b>TeleDeli</b></a></span>
			</div>
		</Provider>
	</HashRouter>
	</React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
