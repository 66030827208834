import React, { useState, useEffect } from 'react'
import axios from 'axios'
import io from 'socket.io-client'
import './Orders.css'
import { resHeader, serverUrl } from '../../lib';


function Orders() {

    const socket = io(serverUrl,
        {
            path: '/orders',
            withCredentials: true,
            extraHeaders: {
                "my-custom-header": "abcd",
                ...resHeader.headers
            }
        }
    );
    const tele = window.Telegram.WebApp;

    const [orders, setOrders] = useState([]);

    useEffect(() => {
        getOrders();
        try {
            socket.on('connect', () => {
                console.log('Connected');
            });

            socket.on('update_orders', () => {
                getOrders();

;
            });

            socket.on('new_order', () => {
                const orderNotification = new Audio('https://audio-previews.elements.envatousercontent.com/files/156322809/preview.mp3?response-content-disposition=attachment%3B+filename%3D%22H42VWCD-notification.mp3%22');
                orderNotification.play()
            })

            return () => {
                socket.off('connect');
                socket.off('update_orders');
            }
        } catch (err) {
            console.log(err);
        }

    }, [])

    const getOrders = async () => {
        try {
            const { data } = await axios.get(`${serverUrl}/api/orders/?${tele.initData}`, resHeader);
            setOrders(data)
        } catch (err) {
            console.log(err);
        }
    }

    const emitTestEvent = () => {
        socket.emit('atest');
    }

    const changeOrderStatus = async (orderNumber) => {
        try {
            await axios.put(`${serverUrl}/api/orders/${orderNumber}`, { userData: tele.initData, status: 'done' }, resHeader);
            //setPaidOrders(orders.filter(paidOrder => paidOrder.orderNumber !== orderNumber));
        } catch (err) {
            console.log(err);
        }
    };

    const removeTaxItemFromOrder = ({cart}) => {
        return cart.reduce((acc, item, idx) => {
            if (item.type !== 'tax') {
                acc.push(
                    <div key={idx} style={{ margin: '0 10px' }}>
                        <span>Item #{idx + 1} <input type="checkbox" name="" id="" /></span>

                        <br />
                        <span>{item.breadType || item.name}</span>
                        <span>{item.sandwichTemp || item.type}</span>
                        <span>Qty: {item.quantity}</span>
                        <br />
                        {/* <ul> */}
                        {item.sandwichItems ? item.sandwichItems.map((sandwichItem, idx) => <span key={idx}>- {sandwichItem.name}</span>) : <span>{item.description}</span>}
                        {/* </ul> */}

                    </div>
                )
            }
            return acc;
        }, [])
    }

    return (
        <div className='orders_portal_container'>
            <h3>Pending Orders</h3>
            <button onClick={emitTestEvent}>Emit Test</button>
            {
                orders.length > 0 ? (orders.map(order => {
                    if (order.status === 'confirmed') {
                        return (
                            <div className='orders_portal_card' key={order._id}>
                                <h2>Order #{order.orderNumber}</h2>
                                <br />
                                <div className='orders_portal_card_items' >
                                    {
                                        removeTaxItemFromOrder(order)
                                    }
                                </div>
                                <br />
                                <button onClick={() => changeOrderStatus(order.orderNumber)}>Done</button>
                            </div>
                        )
                    }
                })) : <span>No Orders!</span>
            }
        </div>
    )
}

export default Orders