import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { removeFromCartAction } from '../../actions/cart';

import './ViewCart.css'

function ViewCart() {
    const {cart} = useSelector(state => state);
    const dispatch = useDispatch();

    useEffect( () => {
        console.log(cart)
    },[])

  return (
  
    <div className='sandwich_cart_container'>
        {
            cart.map((item, idx) => {
                return (
                    <div className='sandwich_cart_card' key={idx}>
                        <span>#{idx+1}</span>
                        <br />
                        <span>{item.breadType || item.name}</span> 
                        <span>{item.sandwichTemp || `${item.type}`}</span>
                        <ul>
                            {
                                (item.sandwichItems) ? item.sandwichItems.map((items, idx) => <li key={idx}>{items.name} {(items.price) ? `($${items.price.toFixed(2)})` : ''}</li>) : <span>{item.description}</span>
                            }
                        </ul>
                        <span>Price: ${item.price.toFixed(2)}</span>
                        <span>Qty: {item.quantity}</span>
                        <br />
                        <button onClick={() => dispatch(removeFromCartAction(idx))}>Remove</button>
                    </div>
                )
            })
        }
    </div>

  )
}

export default ViewCart