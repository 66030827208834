import React from 'react'
import Card from '../Card/Card'

function ItemsRow({ items, itemType, onAdd, onRemove, primaryItems, added, setAdded }) {
	return (
		<div className='type_row'>
			{
				items.map((item, idx) => {
					return <Card item={item} key={idx} onAdd={onAdd} onRemove={onRemove} primaryItem={primaryItems[itemType]} added={added} setAdded={setAdded} />
				})
			}
		</div>

	)
}

export default ItemsRow