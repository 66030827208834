import { ADD_MENU_CATEGORY, DELETE_MENU_CATEGORY, GET_MENU_CATEGORIES, UPDATE_MENU_CATEGORY } from "../actions/menuCategories";

const menuCategoriesReducer = (state = [], action) => {
    switch (action.type) {
        case GET_MENU_CATEGORIES:
            return action.payload;
        case ADD_MENU_CATEGORY:
            return [...state, action.payload];
        case UPDATE_MENU_CATEGORY:
            const updatedCategories = state.map(category => (category._id === action.payload._id) ? action.payload : category);
            return updatedCategories;
        case DELETE_MENU_CATEGORY:
            return state.filter(category => category._id !== action.payload);
        default:
            return state;
    }
}

export default menuCategoriesReducer;