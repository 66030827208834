import { Add, ArrowBack, Close, Remove } from '@mui/icons-material';
import { Button, IconButton } from '@mui/material';
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom'
import { fetchMenuItemsThunk } from '../../actions/menuItems';
import { addToCartAction } from '../../actions/cart';
import './SingleMenuItem.css'

function SingleMenuItem({ currentMenuItem, setCurrentMenuItem }) {
    // const { menuItems } = useSelector(state => state);
    // const { itemId } = useParams();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    // const [item, setItem] = useState(menuItems.length ? menuItems.filter(menuItem => menuItem._id === itemId)[0] : {});
    const [selectedItems, setSelectedItems] = useState([])

    // useEffect(() => {
    //     if (!menuItems.length) dispatch(fetchMenuItemsThunk())
    //     console.log(item)
    // }, []);

    // useEffect(() => {
    //     setItem(menuItems.filter(menuItem => menuItem._id === itemId)[0])
    // }, [menuItems]);

    // useEffect(() => {
    //     console.log(selectedItems)
    // }, [selectedItems])

    const addItems = (variant) => {
        const select = {
            name: currentMenuItem.name,
            description: currentMenuItem.description,
            category: currentMenuItem.category,
            type: variant.variantType,
            price: variant.variantPrice,
            quantity: 1
        }

        const included = { status: false, idx: null };

        if (!selectedItems.length) {
            setSelectedItems([select]);
        } else {
            const updatedSelectedItems = selectedItems.map(selectedItem => selectedItem);

            selectedItems.forEach((selectedItem, idx) => {
                if (selectedItem.type === select.type) {
                    included.status = true;
                    included.idx = idx
                }
            }, []);

            (included.status) ? updatedSelectedItems[included.idx].quantity++ : updatedSelectedItems.push(select)
            setSelectedItems(updatedSelectedItems);
        }


    }

    const removeItem = ({ variantType }) => {
        let updatedSelectedItems = selectedItems.map(selectedItem => selectedItem);
        const included = { status: false, idx: null };

        selectedItems.forEach((selectedItem, idx) => {
            if (selectedItem.type === variantType) {
                included.status = true;
                included.idx = idx
            }
        }, []);

        if (included.status) {
            const { quantity } = updatedSelectedItems[included.idx]
            if (quantity - 1 > 0) {
                updatedSelectedItems[included.idx].quantity--
            } else if (quantity - 1 === 0) {
                updatedSelectedItems = updatedSelectedItems.filter(updated => updated.type !== variantType);
            }
        }
        setSelectedItems(updatedSelectedItems);
    }

    const displayQuantity = ({ variantType }) => {
        const selectedItem = selectedItems.filter(selectedItem => selectedItem.type === variantType)[0];
        return selectedItem ? selectedItem.quantity : 0
    }

    const addToCart = (item) => {
        if (item.name) {
            const localCart = JSON.parse(window.localStorage.getItem('cart') || '[]');
            selectedItems.forEach(selected => {
                localCart.push(selected);
                dispatch(addToCartAction(selected));
            });
            window.localStorage.setItem('cart', JSON.stringify(localCart));
            setCurrentMenuItem({});
        }
    }

    const displayVariants = () => {
        if (currentMenuItem.variants) {
            return currentMenuItem.variants.map(variant => {
                return (
                    <div key={variant.variantType} style={{textAlign: 'center'}}>
                        <span><b>{variant.variantType}</b>: ${variant.variantPrice.toFixed(2)}</span>
                        <br />
                        <IconButton onClick={() => removeItem(variant)} disabled={!displayQuantity(variant)}>
                            <Remove className={!displayQuantity(variant) ? 'qty_btn--disabled' : 'qty_btn'} />
                        </IconButton>
                        {
                            displayQuantity(variant)
                        }
                        <IconButton onClick={() => addItems(variant)}>
                            <Add className='qty_btn' />
                        </IconButton>
                    </div>
                )
            })
        }
    }

    return (
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%' }}>
            <br />
            <div style={{ display: 'flex', justifyContent: 'left', width: '100%' }}>
                <IconButton sx={{color: 'gray'}} onClick={() => setCurrentMenuItem({})}>
                    <Close sx={{color: 'white', backgroundColor: 'gray'}} />
                    <span>&nbsp;Close</span>
                </IconButton>
            </div>

            <h3>{currentMenuItem ? currentMenuItem.name : ''}</h3>
            <span>{currentMenuItem ? currentMenuItem.description : ''}</span>
            <br />

            {
                (currentMenuItem) ? displayVariants() : ''
            }

            <br />
            <Button
                variant='contained'
                disabled={!selectedItems.length > 0}
                onClick={() => addToCart(...selectedItems)}
            >
                ADD TO CART
            </Button>
        </div>
    )
}

export default SingleMenuItem