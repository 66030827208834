import { Close } from '@mui/icons-material';
import { IconButton } from '@mui/material';
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { addMenuItemThunk } from '../../actions/menuItems';
import { tele } from '../../lib';
import MenuItemForm from '../MenuItemForm/MenuItemForm';
import './AddMenuItem.css';

function AddMenuItem({setPortal}) {

    const dispatch = useDispatch();
    //Stores form data 
    const [formData, setFormData] = useState({
        name: '',
        description: '',
        category: '',
        variants: []
    });



    useEffect(() => console.log(formData), [formData]);



    const handleForm = (e, setClear) => {
        e.preventDefault();
        try {
            if (formData.variants.length > 0) {
                dispatch(addMenuItemThunk({
                    userData: tele.initData,
                    item: formData
                }));
                // tele.showAlert('Item added to menu');
                setClear({ status: true })
                console.log('success')
            } else {
                throw 'A variant must be included with menu item'
            }
        } catch (err) {
            tele.showAlert(err);
        }
    }

    return (
        <div className='item_portal'>
			<IconButton onClick={() => setPortal('')}>
				<Close /><span style={{fontSize: '0.8em'}}>Close</span>
			</IconButton>
            <MenuItemForm
                formData={formData}
                setFormData={setFormData}
                handleForm={handleForm}
            />
        </div>
    )
}

export default AddMenuItem