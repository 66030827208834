import React from 'react'
import { AddCircleRounded } from '@mui/icons-material';
import { IconButton } from '@mui/material';
import { useNavigate } from 'react-router-dom';

function MenuItemsCard({item, setCurrentMenuItem}) {
  const navigate = useNavigate();
  
  const navigateToItem = () => {
    navigate(`/menu/${item._id}`)
  }

  return (
    <div key={item._id} style={{ border: '1px solid gray', padding: '5px 10px', width: '100%', display: 'flex', justifyContent: 'space-between' }}>
      <div>
        <span>{item.name}</span>
        <br />
        <span style={{ fontSize: '10pt', color: 'gray' }}>{item.description}</span>
        <br />
        <span>${String(item.variants[0].variantPrice.toFixed(2)) + (item.variants.length > 1 ? '+' : '')}</span>
      </div>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <IconButton onClick={() => setCurrentMenuItem(item)}>
          <AddCircleRounded className='add_btn' fontSize='large' />
        </IconButton>
        {/* <Button style={{fontSize: '18pt', borderRadius: '50px', height: '50px', width: '50px'}} title="+" /> */}
      </div>
    </div>
  )
}

export default MenuItemsCard