import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { fetchMenuItemsThunk } from '../../actions/menuItems';
import MenuItemsCard from '../MenuItemsCard/MenuItemsCard';
import SingleMenuItem from '../SingleMenuItem/SingleMenuItem';
import './MenuItems.css'

function MenuItems() {
	const { menuItems } = useSelector(state => state);
	const dispatch = useDispatch();

	const [currentMenuItem, setCurrentMenuItem] = useState({})

	useEffect(() => {
		if (!menuItems.length) dispatch(fetchMenuItemsThunk());
	}, []);

	const displayMenuItemsByCategory = () => {
		const categoryNames = [];

		menuItems.forEach(item => {
			if(!categoryNames.includes(item.category)){
				categoryNames.push(item.category)
			}
		})
		// console.log('category name', categoryNames)

		return categoryNames.map((category, idx) => {
			return (
				<div key={idx} style={{ display: 'flex', flexDirection: 'column', rowGap: '10px', padding: '0 20px', width: '80%'}}>
					<span style={{fontWeight: 'bold', fontSize: '1.2em'}}>{category}</span>
					{
						menuItems.reduce((acc, item) => {
							if(item.category === category){
								acc.push(<MenuItemsCard key={item.name} item={item} setCurrentMenuItem={setCurrentMenuItem} />)
							}
							return acc;
						}, [])
					}
				</div>
			)
		})
	}

	return (
		<div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', rowGap: '20px'}}>
			{(!currentMenuItem._id) ? <h3>Menu</h3> : ''}
			{
				(!currentMenuItem._id) ? displayMenuItemsByCategory() : <SingleMenuItem currentMenuItem={currentMenuItem} setCurrentMenuItem={setCurrentMenuItem} />
			}
		</div>
	)
}

export default MenuItems;