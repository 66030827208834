import React, { useEffect, useLayoutEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { fetchMenuItemsThunk, updateMenuItemThunk } from '../../actions/menuItems';
import EditMenuItemCard from '../EditMenuItemCard/EditMenuItemCard';
import MenuItemForm from '../MenuItemForm/MenuItemForm';
import { tele } from '../../lib';
import { IconButton } from '@mui/material';
import { Close } from '@mui/icons-material';
import './EditMenuItems.css';

function EditMenuItems({ setPortal }) {
	const dispatch = useDispatch();
	const { menuItems, menuCategories } = useSelector(state => state);
	const [edit, setEdit] = useState(false)
	const [currentItem, setCurrentItem] = useState({});

	useLayoutEffect(() => {
		dispatch(fetchMenuItemsThunk());
	}, [])

	const [formData, setFormData] = useState({
		name: '',
		description: '',
		category: '',
		variants: []
	});


	const handleForm = (e, setClear) => {
		e.preventDefault();
		try {
			dispatch(updateMenuItemThunk({
				userData: tele.initData,
				item: formData
			}));

			console.log('updateItemThunk', currentItem)

			if (tele.initData) tele.showAlert('Item successfully updated!');

			setClear({ status: true })
			setEdit(false);
		} catch (err) {
			// tele.showAlert('Oops, there was a problem. Try again.');
			console.log(err)
		}
		console.log('Submitted')
		console.log(formData)
	}

	const sortCategoryItems = (category) => {
		const sortedItems = menuItems.reduce((acc, item) => {
			if (item.category === category.name) {
				acc.push(<EditMenuItemCard
					key={item._id}
					item={item}
					setPortal={setPortal}
					setCurrentItem={setCurrentItem}
					setEdit={setEdit}
				/>);
			}
			return acc;
		}, []);
		console.log(sortedItems)

		if (sortedItems.length > 0) return sortedItems;
		else return <span>No items</span>
	}

	const displayMenuItemsByCategories = () => {
		return menuCategories.map(category => {
			return (
				<div key={category._id}>
					<h3>{category.name}</h3>
					{
						sortCategoryItems(category)
					}
				</div>
			)
		})

	}

	const toggleDisplay = () => {
		if (!edit) {
			return displayMenuItemsByCategories();
		} else {
			return (
				<MenuItemForm
					item={currentItem}
					formData={formData}
					setFormData={setFormData}
					categories={menuCategories}
					handleForm={handleForm}
				/>
			)
		}
	}

	return (
		<div className='edit_menu_items_container'>
			<IconButton onClick={() => setPortal('')}>
				<Close /><span style={{fontSize: '0.8em'}}>Close</span>
			</IconButton>
				{
					(menuItems.length > 0) ? toggleDisplay() : <h3>No Menu Items</h3>
				}
		</div>
	)
}

export default EditMenuItems